import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import {
  FC,
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";
import clsx from "clsx";
import {
  CanMakePaymentResult,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
} from "@stripe/stripe-js";

import Button from "apps/website/components/base/Button/Button";
import Icon from "apps/website/components/base/Icon/Icon";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import Form from "apps/website/components/form/Form/Form";
import { inputStyles } from "apps/website/components/form/Input/Input";
import Label from "apps/website/components/form/Label/Label";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Image from "apps/website/components/base/Image/Image";
import { DisplayState } from "@/constants/state";
import FormError from "apps/website/components/form-service/fields/Error";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { useAPI } from "apps/website/hooks/useAPI";
import {
  getActionTrackingInfo,
} from "apps/website/utils/tracking/getActionTrackingInfo";
import {
  getFlowFieldValuesForAction,
  useCatsStore,
  useCustomerStore,
  useDiscountStore,
  useNewFormsServiceStore,
} from "@./state";
import { CheckoutBasket, CheckoutStatus } from "@/primary-models";
import ProcessingPaymentModal from "apps/website/components/feature/Modal/ProcessingPaymentModal/ProcessingPaymentModal";
import { useFlow } from "apps/website/contexts/flow";
import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";
// import { useDatadog } from "@auth/client-sdk-react";
import { usePurchaseStore } from "libs/state/src/lib/stores/usePurchaseStore";
import { useDatadog } from "@auth/client-sdk-react";
import { PRODUCT_CATEGORIES } from "libs/form-utils/src/lib/product-categories";

import { processingModalPhraseMap } from "./CardDetailsFormCheckoutV2.map";

const cardInputClasses = `${inputStyles} flex items-center cursor-text [&>*]:w-full [&>*]:font-display`;
const cardInputOptions = { style: { base: { fontFamily: "GreedBold, sans-serif", fontWeight: "900", fontSize: "20px" } } };

const DEFAULT_QUICK_CHECKOUT_ERROR = "Something went wrong, please checkout manually below.";
const DEFAULT_CHECKOUT_ERROR = "Something went wrong, please try another card or check your details and try again.";

export type PaymentDetailsType = "default" | "quickCheckout";
export interface ICardDetailsFormCheckoutV2 {
  isDisabled?: boolean;
  flowSlug: string;
  formUsed: string,
  onSuccess(): void;
  paymentType?: PaymentDetailsType;
  price?: number;
}

const CardDetailsFormCheckoutV2: FC<ICardDetailsFormCheckoutV2> = ({
  isDisabled = false,
  flowSlug,
  formUsed,
  onSuccess,
  paymentType = "default",
  price,
}) => {
  const {
    FormActions: formsActionsAPI,
    Checkout: checkout,
  } = useAPI();

  const posthog = usePostHog();
  const datadog = useDatadog();
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);
  const { setCanUseQuickCheckout, setPurchaseInProgress } = usePurchaseStore();
  const { setFlowFieldValue } = useNewFormsServiceStore();

  const stripe = useStripe();
  const elements = useElements();
  const [ error, setError ] = useState<string | undefined>(undefined);
  const [ isCardNumberComplete, setIsCardNumberComplete ] = useState<boolean>(false);
  const [ isCVCComplete, setIsCVCComplete ] = useState<boolean>(false);
  const [ isExpiryComplete, setIsExpiryComplete ] = useState<boolean>(false);
  const [ isCardDetailsComplete, setIsCardDetailsComplete ] = useState<boolean>(false);
  const [ paymentState, setPaymentState ] = useState<DisplayState>(DisplayState.READY);
  const [ paymentRequest, setPaymentRequest ] = useState<PaymentRequest | null>(null);
  const [ paymentRequestResult, setPaymentRequestResult ] = useState<CanMakePaymentResult | null>(null);
  const [ quickCheckoutError, setQuickCheckoutError ] = useState<string | undefined>(undefined);

  const customerStore = useCustomerStore();
  const { cats } = useCatsStore();
  const discountStore = useDiscountStore();

  const { flow } = useFlow();

  const getSetupIntent = useCallback(async (): Promise<{
    setupIntentClientSecret: string;
    basketId: string;
    existingBasket: string;
  } | void> => {

    const actionTrackingInfo = getActionTrackingInfo(cookies);
    const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowSlug);

    const discountCode = discountStore.discountCodes.find((discount) => discount.flowId === flowSlug)?.discountCode;

    fieldSubmitValuesMap.set("customerId", {
      value: customerStore.customerIds[0].customerId,
      linkingId: "0",
    });

    if (discountCode) fieldSubmitValuesMap.set("discountCode", {
      value: discountCode,
      linkingId: "0",
    });

    const catsArr = cats.find((dc) => dc.flowId === flowSlug)?.catIds || [];
    fieldSubmitValuesMap.set("catIds", { value: catsArr });

    const actionName = flow.mappedFlow.product === "FRESH_TRIAL"
      ? "checkoutFreshNewCustomerV2"
      : "checkoutLitterNewCustomerV2";

    const setupIntentResult = await formsActionsAPI.performAction(
      actionName,
      fieldSubmitValuesMap,
      flow.mappedFlow.product!,
      formUsed,
      undefined,
      posthog.featureFlags.getFlagVariants(),
      actionTrackingInfo,
    );

    if (setupIntentResult.success === false) {
      datadog.logger.log("Error in creating setup intent", {
        error: setupIntentResult,
      }, "error");
    } else {
      datadog.logger.log("Created setup intent", {
        setupIntentResult,
      }, "info");
    }

    console.log({ setupIntentResult });
    // @ts-ignore
    if (setupIntentResult?.value) {
      const {
        setupIntentClientSecret,
        basketId,
        existingBasket,
        // @ts-ignore
      } = setupIntentResult.value;

      return {
        setupIntentClientSecret,
        basketId,
        existingBasket,
      };
    }

  }, [
    posthog,
    cookies,
    customerStore.customerIds,
    discountStore.discountCodes,
    flow.mappedFlow.product, flowSlug,
    formUsed, formsActionsAPI,
  ]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {

    event.preventDefault();
    // setPurchaseInProgress(true);
    setPaymentState(DisplayState.PROCESSING);

    const setup = await getSetupIntent();
    datadog.logger.log("Retrieved setup_intent", {
      basketId: setup?.basketId,
      existingBasket: setup?.existingBasket,
    }, "info");

    if (setup) {
      const {
        setupIntentClientSecret,
        basketId,
        existingBasket,
        // @ts-ignore
      } = setup;

      datadog.logger.log("Setup intent found", {
        setupIntentClientSecret,
        basketId,
        existingBasket,
      }, "info");

      if (existingBasket) {
        const result: CheckoutBasket | null = await checkout.pollBasket(existingBasket);

        if (result === null) {
          datadog.logger.log("Could not find existing basket", {
            existingBasket,
          }, "error");
          setError(DEFAULT_CHECKOUT_ERROR);
          setPaymentState(DisplayState.READY);
        } else if (result.status === CheckoutStatus.SUCCESS) {
          setPaymentState(DisplayState.COMPLETE);
          onSuccess();
        } else {
          datadog.logger.log("Found existing basket, but the status was not success", {
            existingBasket,
            status: result.status,
            userId: result.userId,
          }, "error");
          setError(DEFAULT_CHECKOUT_ERROR);
          setPaymentState(DisplayState.READY);
        }
        setPurchaseInProgress(false);
      }

      if (!setupIntentClientSecret) throw new Error("TODO handle setup intent client secret not passed back");

      try {
        const cardElement = elements?.getElement("cardNumber");

        if (!cardElement) {
          console.error("missing card number on payment details submission.");
          setError("Missing card number");
          setPaymentState(DisplayState.ERROR);
          return;
        }

        const intent = await stripe?.confirmCardSetup(setupIntentClientSecret, {
          payment_method: {
            card: cardElement,
          },
        // return_url: "????"
        });

        if (!intent || intent?.error) {
          datadog.logger.log("Missing setup intent or setup intent errored", {
            intent,
          }, "error");
          setError(intent?.error.message);
          setPaymentState(DisplayState.ERROR);
        }

        if (!intent?.setupIntent) {
          console.error("Setup intent failed.");
          datadog.logger.log("Missing setup intent or setup intent errored", {
            intent,
          }, "error");
          setError(intent?.error.message);
          setPaymentState(DisplayState.ERROR);
          setError("Setup intent failed");
          setPaymentState(DisplayState.READY);
          if (intent?.error) {
            setError(intent?.error.message);
          }
          return;
        }
        setError(undefined);
        setPaymentState(DisplayState.PROCESSING);

        const result: CheckoutBasket | null = await checkout.pollBasket(basketId);

        if (result === null) {
          datadog.logger.log("Could not find basket", {
            basketId,
          }, "error");
          setError(DEFAULT_CHECKOUT_ERROR);
          setPaymentState(DisplayState.READY);
        } else if (result.status === CheckoutStatus.SUCCESS) {
          setPaymentState(DisplayState.COMPLETE);
          onSuccess();
        } else {
          datadog.logger.log("Found basket, but the status was not success", {
            basketId,
            status: result.status,
            userId: result.userId,
          }, "error");
          setError(DEFAULT_CHECKOUT_ERROR);
          setPaymentState(DisplayState.READY);
        }

      } catch (err) {
        datadog.logger.log("Error in payment details submission", {
          error: err,
        }, "error");
        if (err instanceof Error) {
          setError(err?.message);
        }
        setPaymentState(DisplayState.ERROR);
      }
    }
  };

  const processingPhrases = useMemo<string[]>(() => processingModalPhraseMap[PRODUCT_CATEGORIES[flow.mappedFlow.product || "FRESH"]] || [], [ flow.mappedFlow.product ]);

  useEffect(() => {
    setIsCardDetailsComplete(isCardNumberComplete && isCVCComplete && isExpiryComplete);
  }, [ isCardNumberComplete, isCVCComplete, isExpiryComplete, discountStore ]);

  useEffect(() => {
    if (stripe) {
      datadog.logger.log("Making payment", undefined, "info");
      const pr: PaymentRequest = stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        total: {
          label: "KatKin Trial",
          amount: price || 0,
        },
        displayItems: [],
        requestPayerName: true,
        requestPayerEmail: false,
        requestPayerPhone: true,
        requestShipping: true,
        shippingOptions: [ { id: "free", label: "Free Shipping", detail: "Free shipping by DPD", amount: 0 } ],
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          datadog.logger.log("Can make payment", {
            result,
          }, "info");
          setPaymentRequestResult(result);
          setPaymentRequest(pr);
          setCanUseQuickCheckout(true);
        }
      }).catch((paymentError) => {
        datadog.logger.log("Error checking that can make payment", {
          error: paymentError,
        }, "error");
        console.error(paymentError);
      });

    }
  }, [ stripe ]);

  useEffect(() => {
    if (stripe && paymentRequest && price) {
      paymentRequest.update({
        total: {
          label: "KatKin Trial",
          amount: price,
        },
      });
    }
  }, [ stripe, paymentRequest, price ]);

  const fillAddressAndCheckout = (event: PaymentRequestPaymentMethodEvent) => {
    console.log("fill address");
    setPaymentState(DisplayState.PROCESSING);
    if ((event.shippingAddress &&
    event.shippingAddress.country && event.shippingAddress.country.toUpperCase() !== "GB") ||
     !event.shippingAddress ||
     !event.shippingAddress.postalCode ||
     !event.shippingAddress.addressLine?.length ||
     !event.payerPhone || !event.shippingAddress.city) {
      event.complete("invalid_shipping_address");
      datadog.logger.log("PaymentRequestInvalidAddress", {
        eventName: "PaymentRequestInvalidAddress",
        eventData: event,
      }, "warn");
      setPaymentState(DisplayState.READY);
      setQuickCheckoutError("An error using quick checkout, please fill out your details below.");
      return;
    }

    datadog.logger.log("PaymentRequestSuccess", {
      eventName: "PaymentRequestSuccess",
      eventData: event,
    }, "info");

    const addressList = event.shippingAddress.addressLine;
    const half = Math.ceil(addressList.length / 2);
    const addressLine1 = addressList.slice(0, half).reduce((acc, curr) => `${acc}${acc ? ", " : ""}${curr}`, "");
    const addressLine2 = addressList.slice(-half).reduce((acc, curr) => `${acc}${acc ? ", " : ""}${curr}`, "");

    const address = {
      line1: addressLine1 || "",
      line2: addressLine2 || "",
      city: event.shippingAddress.city || "",
      country: event.shippingAddress.country,
      postcode: event.shippingAddress.postalCode || "",
      phone: event.payerPhone || "",
    };
    setFlowFieldValue(flowSlug, "address", { displayValue: {
      billingAddress: address,
      shippingAddress: address,
    },
    submitValue: {
      billingAddress: address,
      shippingAddress: address,
    } });
    posthog.capture("user_action_custom", {
      type: "quick_checkout",
      data: {
        address: {
          billingAddress: address,
          shippingAddress: address,
        },
      },
    });
    event.complete("success");
    console.log("event complete");
  };

  // const debouncedFillAddressAndCheckout = useCallback(debounce(fillAddressAndCheckout, 500), []);

  useEffect(() => {
    if (stripe && paymentRequest && paymentRequestResult) {
      paymentRequest.on("paymentmethod", async (event: PaymentRequestPaymentMethodEvent) => {

        console.log("paymentRequest.on(\"paymentmethod\")");
        console.log(event);
        fillAddressAndCheckout(event);

        const setup = await getSetupIntent();
        datadog.logger.log("Retrieved setup_intent for payment", {
          basketId: setup?.basketId,
          existingBasket: setup?.existingBasket,
        }, "info");
        if (setup) {
          const {
            setupIntentClientSecret,
            existingBasket,
            basketId,
          } = setup;

          console.log("setup");

          const { setupIntent, error: confirmError } = await stripe.confirmCardSetup(
            setupIntentClientSecret,
            { payment_method: event.paymentMethod.id },
            { handleActions: false },
          );
          if (confirmError) {
            datadog.logger.log("Error confirming card setup", {
              error: confirmError,
            }, "error");
            event.complete("fail");
            setQuickCheckoutError(DEFAULT_QUICK_CHECKOUT_ERROR);
          } else {
            event.complete("success");
            console.log("event complete");
            if (setupIntent.status === "requires_action") {
              const { error: requireActionError } = await stripe.confirmCardSetup(setupIntentClientSecret);
              datadog.logger.log("Setup intent requires action", {
                setupIntent,
                requireActionError,
              }, "error");
              if (requireActionError) {
                console.log("requires action");
                setQuickCheckoutError(DEFAULT_QUICK_CHECKOUT_ERROR);
              }
            }
          }
          if (existingBasket) {
            setPaymentState(DisplayState.PROCESSING);
            setPurchaseInProgress(false);
            const result: CheckoutBasket | null = await checkout.pollBasket(existingBasket);

            if (result === null) {
              datadog.logger.log("Could not find existing basket", {
                basketId: existingBasket,
              }, "error");
              setQuickCheckoutError(DEFAULT_QUICK_CHECKOUT_ERROR);
              setPaymentState(DisplayState.READY);
            } else if (result.status === CheckoutStatus.SUCCESS) {
              datadog.logger.log("Found existing basket", {
                existingBasket,
                status: result.status,
                userId: result.userId,
              }, "info");
              setPaymentState(DisplayState.COMPLETE);
              onSuccess();
            } else {
              datadog.logger.log("Found existing basket, but the status was not success", {
                existingBasket,
                status: result.status,
                userId: result.userId,
              }, "error");
              setQuickCheckoutError(DEFAULT_QUICK_CHECKOUT_ERROR);
              setPaymentState(DisplayState.READY);
            }
          } else {
            setPaymentState(DisplayState.PROCESSING);
            setPurchaseInProgress(false);
            const result: CheckoutBasket | null = await checkout.pollBasket(basketId);

            if (result === null) {
              datadog.logger.log("Could not find basket", {
                basketId,
              }, "error");
              setQuickCheckoutError(DEFAULT_QUICK_CHECKOUT_ERROR);
              setPaymentState(DisplayState.READY);
            } else if (result.status === CheckoutStatus.SUCCESS) {
              datadog.logger.log("Found basket", {
                basketId,
                status: result.status,
                userId: result.userId,
              }, "info");
              setPaymentState(DisplayState.COMPLETE);
              onSuccess();
            } else {
              datadog.logger.log("Found basket, but the status was not success", {
                basketId,
                status: result.status,
                userId: result.userId,
              }, "error");
              setQuickCheckoutError(DEFAULT_QUICK_CHECKOUT_ERROR);
              setPaymentState(DisplayState.READY);
            }
          }
        }
      });

      paymentRequest.on("cancel", async () => {
        setPurchaseInProgress(false);
      });
    }
  }, [ paymentRequest, paymentRequestResult, stripe ]);

  return (
    <>
      { (paymentType === "quickCheckout") ? (
        <>
          { paymentRequest && (
            <>
              <Column>
                { paymentRequest && (
                  <>
                    <PaymentRequestButtonElement
                      options={{ paymentRequest }}
                      onClick={() => setPurchaseInProgress(true)}
                    />
                  </>
                ) }
              </Column>
              { quickCheckoutError && (
                <Column >
                  <Spacer size="md" />
                  <FormError>{ quickCheckoutError }</FormError>
                </Column>
              ) }
            </>
          ) }
        </>
      ) : (
        <Form action="" onSubmit={handleSubmit}>
          <fieldset>
            <Grid>
              <Column>
                <legend className="flex justify-between">
                  <Text tag="h2" size={legacySizeCollectionMap.titleSm} display="subtitle">Payment details</Text>
                  <div className="flex">
                    <div className="max-w-[30px] flex items-center justify-center mr-2">
                      <Image image={{ src: "/images/checkout/visa.svg", width: 30, height: 30 }} alt="Visa" />
                    </div>
                    <div className="max-w-[36px] flex items-center justify-center mr-2">
                      <Image image={{ src: "/images/checkout/mastercard.png", width: 1200, height: 720 }} alt="Mastercard" />
                    </div>
                    <div className="max-w-[30px] flex items-center justify-center">
                      <Image image={{ src: "/images/checkout/amex.svg", width: 30, height: 30 }} alt="American Express" />
                    </div>
                  </div>
                </legend>
              </Column>
              <Column>
                <Label label="Card number" hideLabel={false} UUID="cardNumber" fontStyle="alternative">
                  <CardNumberElement
                    className={cardInputClasses}
                    options={cardInputOptions}
                    onChange={(event) => setIsCardNumberComplete(event.complete)}
                  />
                </Label>
              </Column>
              <Column spans={6}>
                <Label label="CVC" hideLabel={false} UUID="cardCvc" fontStyle="alternative">
                  <CardCvcElement
                    className={cardInputClasses}
                    options={cardInputOptions}
                    onChange={(event) => setIsCVCComplete(event.complete)}
                  />
                </Label>
              </Column>
              <Column spans={6}>
                <Label label="Expiry" hideLabel={false} UUID="cardExpiry" fontStyle="alternative">
                  <CardExpiryElement
                    className={cardInputClasses}
                    options={cardInputOptions}
                    onChange={(event) => setIsExpiryComplete(event.complete)}
                  />
                </Label>
              </Column>
              <>
                { error && (
                  <Column >
                    <FormError>{ error }</FormError>
                  </Column>
                ) }
              </>
            </Grid>
          </fieldset>
          <Spacer size="xl" />
          <Grid>
            <Column justify="center" align="center">
              <Button type="submit" disabled={!stripe || isDisabled || !isCardDetailsComplete} state={paymentState}>
                <span className="flex justify-center items-center"><Icon icon="padlock" color="inherit" size="xsmall" /><span className="ml-2">Place my order</span></span>
              </Button>
            </Column>
          </Grid>
          <Section
            theme="brand"
            size="sm"
            className={clsx(
              "fixed bottom-0 left-0 w-full p-4 z-30",
            )}>
            <Container
              className="flex items-center justify-center flex-col-reverse md:flex-row"
            >
              <Button type="submit" disabled={!stripe || isDisabled || !isCardDetailsComplete} state={paymentState} color="secondary">
                <span className="flex justify-center items-center"><Icon icon="padlock" color="inherit" size="xsmall" /><span className="ml-2">Place my order</span></span>
              </Button>
            </Container>
          </Section>
        </Form>
      ) }

      { (paymentState === DisplayState.COMPLETE || paymentState === DisplayState.PROCESSING) && (
        <ProcessingPaymentModal phrases={processingPhrases}
          processingComplete={paymentState === DisplayState.COMPLETE}
        />
      ) }

    </>
  );
};

export default CardDetailsFormCheckoutV2;
