"use client";

import {
  ComponentType,
  FC,
  forwardRef,
  LegacyRef,
  useCallback,
  MouseEvent,
  PropsWithChildren,
} from "react";
import clsx from "clsx";
import Link from "next/link";

export type ButtonSize = "xs" | "sm" | "md" | "lg" | "xl";

const buttonSizeClassMap: Record<ButtonSize, string> = {
  xs: "m-1 px-2.5 rounded py-1.5 text-xs font-medium  shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
  sm: "m-1 px-3 py-2 rounded-md text-sm font-medium leading-4  shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
  md: "m-1 px-4 py-2 rounded-md text-sm font-medium  shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
  lg: "m-2 px-y py-2 rounded-md text-base font-medium  shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
  xl: "m-2 px-6 py-3 rounded-md text-base font-medium  shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
};

export type ButtonVariant = "primary" | "secondary" | "tertiary" | "warning";

const buttonVariantClassMap: Record<ButtonVariant, string> = {
  primary: "text-white bg-indigo-600 hover:bg-indigo-700 border-transparent ",
  secondary: "text-indigo-700 bg-indigo-100 hover:bg-indigo-200 border-transparent ",
  tertiary: "bg-white border-gray-300 text-gray-700 hover:bg-gray-100 border border-gray-300",
  warning: "text-white bg-red-600 hover:bg-red-700 border-transparent ",
};

interface BaseProps extends PropsWithChildren {
  size?: ButtonSize;
  variant?: ButtonVariant;
  Icon?: ComponentType;
}

interface AnchorTagProps extends BaseProps {
  href: string;
  newTab?: boolean;
}

interface ButtonProps extends BaseProps {
  onClick?: () => void;
  disabled?: boolean;
  type?: React.ComponentProps<"button">["type"];
}

type Props = AnchorTagProps | ButtonProps;

// eslint-disable-next-line react/display-name
export const Button: FC<AnchorTagProps | ButtonProps> = forwardRef(({
  size = "md",
  variant = "primary",
  children,
  Icon,
  ...otherProps
}: Props, ref: LegacyRef<HTMLButtonElement>) => {

  const className = clsx(
    "inline-flex justify-center items-center rounded border no-underline",
    buttonSizeClassMap[size],
  );

  const buttonHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    if ("type" in otherProps && otherProps.type !== "submit") {
      e.preventDefault();
    }

    if ("onClick" in otherProps && otherProps?.onClick) {
      otherProps.onClick();
    }

  }, [ otherProps ]);

  if ("href" in otherProps) {
    const { href, newTab = false } = otherProps;
    return (
      (<Link
        href={href}
        data-component={Button.name}
        {...(newTab ? {
          target: "_blank",
          rel: "noopener noreferrer",
        } : {})}
        className={clsx(className, buttonVariantClassMap[variant])}>

        { Icon && <Icon /> }
        { children }

      </Link>)
    );
  }

  const { disabled, type } = otherProps;

  return (
    <button
      ref={ref}
      type={type ?? "button"}
      disabled={disabled}
      onClick={buttonHandler}
      data-component={Button.name}
      className={clsx(
        className,
        disabled ? "bg-gray-200 text-gray-50" : buttonVariantClassMap[variant],
      )}
    >
      { Icon && <Icon /> }
      { children }
    </button>
  );
});
